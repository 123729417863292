import { CURRENCIES_SYMBOL } from "src/app/core/common/currency-constants";
import { ProhibitionType } from "src/app/core/models/domain/prohibition/ProhibitionType";

export const campaignConfig = {
  campaignProhibitions: ["None", ProhibitionType.ALCOHOL, ProhibitionType.CANNABIS, ProhibitionType.NICOTINE],
  costTypeDisplayConfig: {
    content: [
      {
        code: "PRODUCTION",
        order: 1,
      },
    ],
  },
  displayPlacementId: true,
  showFrameImages: true,
  setCampaignAudienceToPrimaryAudience: false,
  isRouteVersionMandatory: false,
  isDateFormatDDMMYYYY: false,
  hfss_disabled: true,
  loadInchargeStartDates: false,
  displayHFSS: false,
  displayProhibition: true,
  displayPrimaryAudience: false,
  displayTargetAudience: true,
  displayRouteVersion: false,
  updateBillingUnitWithMOVal: false,
  displayValueMediaAndPacks: false,
  currencySymbol: CURRENCIES_SYMBOL.US,
  disableCursor: true,
  displayFeePercentageCol: true,
  downloadCampaignBookings: true,
  exportCampaign: true,
  allowMediaUpload: true,
  currencyList: ["USD", "CAD", "GBP"],
  addExchangeMultiplierControl: true,
  addFeeCalculationTypeControl: true,
  allowCampaignLevelMarginMarkup: false,
  allowMarginMarkupToEdit: true,
  customAgencySACValue: false,
  addPrincipalBeneficiaryControl: true,
  displayClientOrderNumber: true,
  displayAgencyCode: false,
  displayCustomerReferenceNumber: false,
  showPlacementReference: true,
  showCustomerReference: true,
  hideExternalProductionCostAndPostingCharges: true,
  disableTvRegionForNationalReach: true,
  isTvRegionMandatoryInFFCGeography: true,
  displayCancellationPolicyPop: true,
  closeCampaignSaveConfirmation: true,
  disableSave: false,
  shouldDisableLineAmendment: false,
  hideCustomerReference: true,
  setMarginLabel: false,
  showPrepareOption: false,
  showAvailsAction: false,
  displayAmendment: false,
  displayExternalProdCost: false,
  displayBillingUnit: true,
  loadGeoLocationForStateAndCountry: true,
  updateDMADropDown: true,
  setPrimaryAudience: true,
  setPrimaryAudienceAndKPI: false,
  displayMediaOwnerFrameRef: true,
  ukSpecificCampaignBudgetComp: false,
  countryCodeForFinanceIdMapping: "US",
  showCityFieldInFFC: false,

  /* CAMPAIGN EXPORT */
  enablePBUExport: true,
  enablePrismaExport: true,
  enablePhotosheetExport: true,
  enablePoPTrackerExport: true,
  enableSupplierContract: true,
  displayAdaAudience: true,
  adaAudienceCountryCode: "us",
  showRFPTab: true,

  /* CAMPAIGN LINES GRID */
  enablePagination: true,
  defaultPageSize: 100,
  showPageSizeOptions: true,
  pageSizeOptions: [10, 20, 50, 100],
  campaignLineFilters: [
    "channel",
    "schedule",
    "status",
    "openServiceRequest",
    "mediaOwner",
    "format",
    "environment",
    "tags",
    "reach",
    "mopActions",
    "country",
    "currency",
    "market",
    "customerReference",
  ],
  marketFilterHeader: "Market",
};
